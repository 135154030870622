import { createTheme } from "@mui/material"
import { ruRU as pickersRuRU } from "@mui/x-date-pickers/locales"
import { ruRU } from "@mui/material/locale"

export const lightTheme = createTheme(
  {
    palette: {
      mode: "light",
      // primary: {
      //   main: "#B71C1C",
      //   light: "#F05545",
      //   dark: "#7F0000",
      //   contrastText: "#FFFFFF",
      // },
      // secondary: {
      //   main: "#C68400",
      //   light: "#FFCC80",
      //   dark: "#a06d00",
      //   contrastText: "#000000",
      // },
      // divider: "#7F0000",
      // background: {
      //   default: "#FFFFFF",
      //   paper: "#F5F5F5",
      // },
      // text: {
      //   primary: "#000000",
      //   secondary: "#616161",
      //   disabled: "#9E9E9E",
      // },
      // error: {
      //   main: "#D32F2F",
      //   light: "#FF6659",
      //   dark: "#9A0007",
      //   contrastText: "#FFFFFF",
      // },
      // warning: {
      //   main: "#FFA000",
      //   light: "#FFD149",
      //   dark: "#C67100",
      //   contrastText: "#000000",
      // },
      // info: {
      //   main: "#1976D2",
      //   light: "#4791DB",
      //   dark: "#115293",
      //   contrastText: "#FFFFFF",
      // },
      // success: {
      //   main: "#388E3C",
      //   light: "#66BB6A",
      //   dark: "#00600F",
      //   contrastText: "#FFFFFF",
      // },
    },
  },
  ruRU,
  pickersRuRU,
)
