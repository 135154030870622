import { createTheme } from "@mui/material"
import { ruRU as pickersRuRU } from "@mui/x-date-pickers/locales"
import { ruRU } from "@mui/material/locale"

export const darkTheme = createTheme(
  {
    palette: {
      mode: "dark",
      //   primary: {
      //     main: "#C2383D",
      //     // light: '#B8282D',
      //     // dark: '#972125',
      //     contrastText: "#E8E8EE",
      //   },
      //   secondary: {
      //     main: "#EA7E1F",
      //     // light: '#EE9344',
      //     // dark: '#CD6A13',
      //     contrastText: "#241E1A",
      //   },
      //   divider: "#6E8787",
      //   background: {
      //     default: "#121A21",
      //     paper: "#1e262c",
      //   },
      //   text: {
      //     primary: "#E8E8EE",
      //     secondary: "#E8E8EE",
      //     disabled: "#A4A2A8",
      //   },
      //   error: {
      //     main: "#BA2222",
      //     light: "#CF2626",
      //     dark: "#D03111",
      //     contrastText: "#E8E8EE",
      //   },
      //   warning: {
      //     main: "#D43111",
      //     light: "#ED3F1D",
      //     dark: "#008F39",
      //     contrastText: "#D0D0DC",
      //   },
      //   info: {
      //     main: "#006E2C",
      //     light: "#008F39",
      //     dark: "#085A72",
      //     contrastText: "#D0D0DC",
      //   },
      //   success: {
      //     main: "#66BB6A",
      //     light: "#98EE99",
      //     dark: "#338A3E",
      //     contrastText: "#DCDCE5",
      //   },
      //   tonalOffset: {
      //     dark: 0.2,
      //     light: 0.2,
      //   },
      //   // contrastThreshold: 3,
      //   // common: {
      //   //   black: '#171311',
      //   //   white: '#DCDCE5',
      //   // },
      //   action: {
      //     hoverOpacity: 0.2,
      //     selectedOpacity: 0.3,
      //     disabledOpacity: 0.3,
      //     focusOpacity: 0.2,
      //     activatedOpacity: 0.0,
      //   },
    },
    typography: {
      fontSize: 14,
    },
  },
  ruRU,
  pickersRuRU,
)
